import React from "react"
import styled from "styled-components"

// Components
import Container from "./layout/Container"
import Divider from "./layout/Divider"
import { Row, Col } from "./layout/Grid"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { fontWeights, fonts } from "../styles/fonts"

interface IProps {
  pageTitle: string
  introTitle: string
  introCopy: {
    html: string
  }
  imageURL?: string
}

const PageHeader = ({ pageTitle, introTitle, introCopy, imageURL }: IProps) => (
  <Wrapper>
    <Container>
      <div>
        <Divider color={colors.white} />
      </div>

      <Row>
        <Col m={6}>{pageTitle && <PageTitle>{pageTitle}</PageTitle>}</Col>

        <Col xxs={6} xxsOffset={2} m={6} mOffset={2}>
          {introTitle && (
            <IntroTitleWrapper>
              <IntroTitle>{introTitle}</IntroTitle>
            </IntroTitleWrapper>
          )}

          {introCopy && introCopy.html && (
            <div>
              <IntroCopy
                dangerouslySetInnerHTML={{
                  __html: introCopy.html,
                }}
              />
            </div>
          )}
        </Col>
        {imageURL && <img src={imageURL} alt="header image" />}
      </Row>
    </Container>
  </Wrapper>
)

export default PageHeader

// Main styles
const Wrapper = styled.div`
  margin-bottom: 75px;
`

const PageTitle = styled.h1`
  ${textStyles.body};
  font-weight: ${fontWeights.medium};
`

const IntroTitleWrapper = styled.div``

const IntroTitle = styled.h2`
  ${textStyles.headingS};
  margin-bottom: 20px;
`

const IntroCopy = styled.div`
  font-family: ${fonts.primary};

  a {
    ${textStyles.hyperlink};
    color: ${colors.white};
    text-decoration: none;

    &:hover {
      color: ${colors.accent1};
    }
  }

  *:last-child {
    margin-bottom: 0;
  }
`
