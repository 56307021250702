import React from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/PageHeader"
import TipsBlock from "../components/TipsBlock"
import BlogsBlock from "../components/BlogsBlock"

// Data
import content from "../../static/content"

export default () => (
  <Layout>
    <SEO title="Home" />
    <PageHeader
      pageTitle={content.homepage.heading.introTitle}
      introTitle={content.homepage.heading.title}
      introCopy={content.homepage.heading.text}
    />
    <TipsBlock />
    <BlogsBlock />
  </Layout>
)
