import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Styling
import { mq } from "../styles/utils/mq"

// Types
import { ICase } from "./BlogsBlock"

// Data
import content from "../../static/content"
import { fonts } from "../styles/fonts"

const ImageGrid = () => (
  <Wrapper>
    {content.homepage.articles.cases.map((article: ICase, index: number) => (
      <Item key={index}>
        <ImageWrapper>
          <ItemDetails>
            {article.type && <ItemCopy>{article.type}</ItemCopy>}
            {article.company && <ItemCopy>&mdash; {article.company}</ItemCopy>}
          </ItemDetails>
          <Link
            to={
              "/article/?" +
              article.title
                .replace(/-/g, "_")
                .split(" ")
                .join("-")
                .toLowerCase()
            }
            state={{ data: article }}
          >
            {article.image.small && (
              <ItemImage src={article.image.small} alt={article.image.alt} />
            )}
          </Link>
        </ImageWrapper>
      </Item>
    ))}
  </Wrapper>
)

export default ImageGrid

const Wrapper = styled.div`
  width: 100%;
  padding-top: 25px;
  ${mq.from.M`
    display: flex;
  `}

  flex-flow: row wrap;
  align-items: stretch;
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
`

const ItemDetails = styled.div`
  margin-bottom: 10px;
  margin-top: 0px;
  position: absolute;
  top: -50px;
  padding-left: 10px;

  ${mq.from.L`
    padding-left: 25px;
  `}
`

const Item = styled.div`
  position: relative;
  padding-bottom: 20px;
  line-height: 0;
  margin: 50px 0;

  /* Styling for every first of six element */
  &:nth-child(6n + 1) {
    flex: 0 21.49%;
  }

  /* Styling for every second of six element and so on */
  &:nth-child(6n + 2) {
    flex: 0 57.02%;
  }

  &:nth-child(6n + 3) {
    flex: 0 21.49%;
    margin: auto 0 50px 0;
  }

  &:nth-child(6n + 4) {
    flex: 0 57.02%;
  }

  &:nth-child(6n + 5) {
    flex: 0 21.49%;
  }

  &:nth-child(6n + 6) {
    flex: 0 21.49%;
    margin: auto 0 50px 0;
  }

  &:not(:last-of-type) {
    margin-bottom: 75px;
  }

  ${mq.from.S`
    margin-bottom: 0;
  `}

  ${mq.from.M`
    &:nth-child(6n + 1) {
      div div {
        top: -50px;
      }
    }

    &:nth-child(6n + 2) {
      div div {
        top: -50px;
      }
    }

    &:nth-child(6n + 3) {
      margin: auto 0 75px 0;
      div div {
        top: -50px;
      }
    }

    &:nth-child(6n + 4) {
      div div {
        bottom: -60px;
        top: auto;
      }
    }

    &:nth-child(6n + 5) {
      div div {
        bottom: -60px;
        top: auto;
      }
    }

    &:nth-child(6n + 6) {
      margin: auto 0 75px 0;
      div div {
        bottom: -60px;
        top: auto;
      }
    }
  `}
`

const ItemCopy = styled.p`
  margin: 0;
  display: block;
  line-height: 1;
  font-family: ${fonts.primary};
`

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  max-height: 400px;
`
