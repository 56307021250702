import React from "react"
import styled from "styled-components"

// Components
import { Row, Col } from "./layout/Grid"
import Container from "./layout/Container"
import Divider from "./layout/Divider"
import { List, Wrapper } from "./TipsBlock"

// Data
import content from "../../static/content"

// Styles
import colors from "../styles/colors"
import ImageGrid from "./ImageGrid"

export interface ICase {
  company: string
  title: string
  date: string
  type: string
  author: string
  slogan: string
  intro: string
  image: {
    small: string
    large: string
    alt: string
  }
  video: {
    source: string
  }
  story: string
}

const BlogsBlock = () => (
  <Wrapper id="cases">
    <Container>
      <Row>
        <Col m={14} mOffset={1}>
          {content.homepage.articles.title && (
            <Header>
              <Divider color={colors.white} />
              <Title>{content.homepage.articles.title}</Title>
            </Header>
          )}

          {content.homepage.tips.lessons.length > 0 && (
            <List>
              <ImageGrid />
            </List>
          )}
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

export default BlogsBlock

const Header = styled.div``

const Title = styled.h2`
  margin-bottom: 25px;
`
